import React from "react"

import styles from "./marquee.module.scss"

const Marquee = ({ title, left, right, direction = 1 }) => {
  let heading = React.useRef()

  React.useEffect(() => {
    if (heading.current == null) return
    let frameId = null
    let offs = 0
    function frame() {
      let h = heading.current
      if (h != null) {
        let width = h.children[0].getBoundingClientRect().width
        h.style.transform = `translateX(${
          direction === 1 ? -(offs % width) : -width - (offs % width)
        }px)`
      }
      offs += 2 * direction
      window.requestAnimationFrame(frame)
    }
    frame()

    return () => {
      window.cancelAnimationFrame(frameId)
    }
  }, [heading, direction])

  return (
    <div className={styles.module}>
      <h2 hidden={!title} className={styles.heading} ref={heading}>
        <span>{title + " "}</span>
        <span aria-hidden={true}>{title + " "}</span>
        <span aria-hidden={true}>{title + " "}</span>
      </h2>
      <div className="wrapper">
        <div className="columns is-centered is-multiline">
          <div className="column is-10-tablet is-6-desktop is-5-widescreen">
            <div
              className={`user-content ${styles.left}`}
              dangerouslySetInnerHTML={{
                __html: left,
              }}
            />
          </div>
          <div className="column is-10-tablet is-6-desktop is-5-widescreen">
            <div
              className="user-content body-large-after-desktop"
              dangerouslySetInnerHTML={{
                __html: right,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Marquee
